<template>
  <sub-page
      :title="$t('ProjectDashboard')"
      icon="mdi-cog"
      v-model="$store.state.settings.pageData"
  >

    <h1>{{ uuid }}</h1>

  </sub-page>


</template>

<script>
export default {
  name: "projectViewer",
  props : {
    uuid : {
      type : String,
      default : ''
    }
  },
  data() {
    return {
      entity : {
        name : 'Super project'
      },
      items : []
    }
  },
  computed : {
  }
}
</script>

<style scoped>

</style>